.btn-circle {
    width: 30px;
    height: 30px;
    text-align: center;
    padding: 6px 0;
    font-size: 12px;
    line-height: 1.428571429;
    border-radius: 15px;
  }

.hightlight-border-team {
  width: 260px;
  margin-right: 1%;
  border: solid 2px #DCB839;
}

.border-mint-card {
  border: solid 2px #DCB839;
  border-radius: 6px;
}
.secondary-mint-card {
  opacity: 50%;
}
* {
  box-sizing: border-box;
}


h1 {
  letter-spacing: 2px;
  text-align: center;
  text-transform: uppercase;
}

.countdown-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
}

.countdown-item {
  color: #FFF;
  font-size: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  line-height: 30px;
  margin: 10px;
  padding-top: 10px;
  position: relative;
  width: 100px;
  height: 100px;
}

.countdown-item span {
  color: #FFF;
  font-size: 12px;
  font-weight: 600;
  text-transform: uppercase;
}

.countdown-svg {
  position: absolute;
  top: 0;
  left: 0;
  width: 100px;
  height: 100px;
}

@media screen and (max-width: 600px) {
  .countdown-item span {
    color: #FFF;
    font-size: 12px;
    font-weight: 400;
    text-transform: uppercase;
  }

  .countdown-svg {
    position: absolute;
    top: 0;
    left: 0;
    width: 50px;
    height: 50px;
  }

  .countdown-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: nowrap;
  }
}

.bg-head {
  background-color: #242424;
  height: 350px;
  /* position: relative; */
}
.bg-collect {
  /* position: absolute; */
  margin-top: -17%;

}
.card-worst .card-header {
  border-bottom: 0;
}

.card-worst:hover {
  border: 3px solid #DCB839;
  border-radius: 6px;
}

.img-mutant {
  border: 4px solid #DCB839;
  border-radius: 32px;
}

.btn-mutant {
  margin: 10px;
  font-size: 20px;
  padding: 15px;
  text-align: center;
  text-transform: uppercase;
  transition: 0.5s;
  background-size: 200% auto;
  color: #FFF;
  box-shadow: 0 0 20px #eee;
  border-radius: 10px;
  width: 200px;
  box-shadow: 0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24);
  transition: all 0.3s cubic-bezier(.25,.8,.25,1);
  cursor: pointer;
  display: inline-block;
  border-radius: 0px;
  background-image: linear-gradient(74deg, rgba(220,184,57,1) 0%, rgba(147,89,31,1) 100%);
}

.img-circle {
  border-radius: 50%;
  width: 15%;
}

.blur-card {
  opacity: 0.5;
}

.select-card {
  border: 3px solid #DCB839;
  border-radius: 6px;
}

.card-worst .hover-to-cancel:hover {
  opacity: 0.5;
}
.card-worst .hover-to-cancel:hover .middle {
  opacity: 1;
}


.middle {
  transition: .5s ease;
  opacity: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  text-align: center;
}
